import { useEffect, useRef } from 'react';
import { Button, Card } from 'antd';
import { Redirect, useHistory } from 'react-router';
import classnames from 'classnames';

import { useStoreState } from 'Store';
import { generateQRCodeLink } from 'Utils/generateQRCodeLink';
import { formatMessage } from 'Utils/formatMessage';
import IconCircle from 'Components/Common/IconCircle';
import shareQRIcon from 'Assets/icons/🤳🏼.png';

import classes from './ShareQR.module.scss';
import RequesterLayout from 'Components/Layouts/RequesterLayout';
import { qrCode } from './CustomQRCode';

const ShareQRContainer: React.FC = () => {
  const history = useHistory();
  const ref = useRef<any>(null);

  const requestToken = useStoreState(
    (state) => state.paymentRequest.paymentRequestToken
  );

  const authToken = useStoreState((state) => state.staffAuth.token);

  const data = useStoreState((state) => state.paymentRequest);

  const onGoBack = () => {
    history.goBack();
  };

  const QR = {
    path: 'invoice',
    token: requestToken,
  };

  const url = generateQRCodeLink(QR);

  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: url,
    });
  }, [url]);

  if (!authToken) return <Redirect to="/staff-login" />;

  return (
    <RequesterLayout title="Requester">
      <div className={classes['content']}>
        <div className={classes['content-container']}>
          <div className={classes['icon-container']}>
            <IconCircle iconSrc={shareQRIcon} color="blue-lighter" size={80} />
          </div>
          <div className={classes['title-container']}>
            <p className={classes['title']}>
              {formatMessage('share.qr.title')}
            </p>
            <p className={classes['subtitle']}>
              {formatMessage('share.qr.subtitle')}
            </p>
          </div>

          <Card className={classes['card']}>
            <div className={classes['card__billing']}>
              <p>Amount</p>
              <p>${data.amount}</p>
            </div>
            <div className={classes['card__billing']}>
              <p>Fee</p>
              <p>{data.feeRate}%</p>
            </div>
            <div className={classes['card__billing']}>
              <p>Additional</p>
              <p>{data.gst ? 'GST' : 'No GST'}</p>
            </div>
            <div className={classes['card__billing']}>
              <p>Total</p>
              <p>${data.total}</p>
            </div>
          </Card>
          <div className={classes['qr-container']}>
            <div ref={ref} />
          </div>
          <div className={classes['button-container']}>
            <Button
              htmlType="submit"
              type="primary"
              className={classnames(classes['button-next'])}
              onClick={onGoBack}
            >
              {formatMessage('btn_cancel')}
            </Button>
          </div>
        </div>
      </div>
    </RequesterLayout>
  );
};

export default ShareQRContainer;
