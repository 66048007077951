import { Button } from 'antd';
import { useHistory } from 'react-router';

import { useStoreActions } from 'Store';
import { formatMessage } from 'Utils/formatMessage';
import IconCircle from 'Components/Common/IconCircle';
import RequesterLayout from 'Components/Layouts/RequesterLayout';
import expiredIcon from 'Assets/icons/forbidden.png';

import classes from './ExpiredStatus.module.scss';
import { PATH_NAME } from 'Constants/PathName.Constant';

const ExpiredStatus: React.FC = () => {
  const history = useHistory();
  const setInitData = useStoreActions((actions) => actions.invoice.setInitData);

  const tryAgain = () => {
    setInitData(false);
    history.push(PATH_NAME.INVOICE);
  };

  return (
    <RequesterLayout title="Requester">
      <div className={classes['content']}>
        <div className={classes['content-container']}>
          <div className={classes['icon-container']}>
            <IconCircle iconSrc={expiredIcon} color="red-light" size={80} />
          </div>
          <div className={classes['title-container']}>
            <p className={classes['title']}>
              {formatMessage('status.fail.title')}
            </p>
            <p className={classes['subtitle']}>
              {formatMessage('status.fail.subtitle')}
            </p>
          </div>

          <div className={classes['button-container']}>
            <Button
              type="primary"
              className={classes['button-next']}
              onClick={tryAgain}
            >
              {formatMessage('btn_try_again')}
            </Button>
          </div>
        </div>
      </div>
    </RequesterLayout>
  );
};

export default ExpiredStatus;
